import HTTP from '@/plugins/axios';

export const getCompanies = async(params: any) => {
  const response = await HTTP.get('/jobnews/employers', {
    params,
  });
  return response.data;
};

export const getCompanySearch = async(params: any) => {
  const response = await HTTP.get('/jobnews/employer_search', {
    params,
  });
  return response.data;
};

export const getCompanyInfo = async(params: any) => {
  const response = await HTTP.get('/jobnews/employer_info', {
    params,
  });
  return response.data;
};

export const getDetailedCompanies = async(companies: number[], sid: string) => {
  const response = await HTTP.post(
    '/jobnews/company_jobs_distribution',
    { companies },
    {
      params: {
        sid,
      },
    },
  );
  return response.data;
};

type SubscribeCompanyNewsletterPayload = {
  email: string
  sid: string
  company_id: number
  applicant_id?: number
  periodicity: number
};

export const subscribeCompanyNewsletter = async(payload: SubscribeCompanyNewsletterPayload) => {
  const response = await HTTP.post('/company/newsletter_external_subscribe', { ...payload });
  return response.data;
};

export const getCompanyJobs = async(params?: any) => {
  const response = await HTTP.get('/company/jobs', {
    params,
  });
  return response.data;
};
