export const SERVER_URL = 'https://job-server.net';

export const GOOGLE_MAP_KEY = 'AIzaSyD3uhokT0li-ms20myh_Dyi7ALNSQkSm-E';// 'AIzaSyD3uhokT0li-ms20myh_Dyi7ALNSQkSm-E';

export const EVENT_JOB_CLICK = 406;

export const EVENT_COMPANY_PROFILE_VIEW = 405;

export const EVENT_APPLICANT_PROFILE_VIEW = 404;

export const MINIFIED_API_KEY = '5cccc9f6-136c-57f5-a235-5dd73b1ab9fe';
