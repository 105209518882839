import { MINIFIED_API_KEY } from '@/config/constant';
import HTTP from '@/plugins/axios';

export const getPortalSetting = async(params: any) => {
  const response = await HTTP.get('/portal/settings', {
    params,
  });
  return response.data;
};
export const getPortalJobCount = async(params: any) => {
  const response = await HTTP.get('/portal/job_count', {
    params,
  });
  return response.data;
};
export const getSocialNetworkLink = async() => {
  const response = await HTTP.get('/consultant/social_network_link');
  return response.data;
};
export const getMinifiedLink = async(url: string) => {
  const response = await HTTP.post(
    '/minify_link',
    {
      url,
    },
    {
      headers: {
        'X-Api-Key': MINIFIED_API_KEY,
      },
    },
  );
  return response.data;
};
