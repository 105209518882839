<template>
  <div class="component-widget p-3 content-color">
    <template v-if="isAuth">
      <b-form @submit.prevent="submitApplicantNewsletter">
        <p v-html="replaceCompanyPlaceholder(translations[13869])"></p>
        <b-row class="g-3 my-3">
          <b-col cols="12" md="4">
            <b-checkbox v-model="isNewsletterAccepted" switch size="sm">
              <span v-html="replaceCompanyPlaceholder(translations[13870])"></span>
            </b-checkbox>
          </b-col>
          <b-col cols="12" md="4">
            <p class="fs-6">{{ translations[13521] }}</p>
            <b-select v-model="selectedPeriod" :options="periodOptions" size="sm" class="w-auto" />
          </b-col>
          <div class="col-12">
            <b-button :disabled="!isNewsletterAccepted" variant="primary" size="sm" type="submit">{{ translations[13868] }}</b-button>
          </div>
        </b-row>
      </b-form>
    </template>
    <template v-else>
      <b-form @submit.prevent="subscribeGuestNewsletter">
        <p v-html="replaceCompanyPlaceholder(translations[13871])"></p>
        <b-row class="g-3 my-3">
          <b-col cols="12" md="4">
            <label for="email-newsletter"
              ><small
                ><b>{{ translations[9207] }}</b></small
              ></label
            >
            <b-input v-model="guestEmail" type="email" size="sm" id="email-newsletter" :placeholder="translations[13872]" required />
          </b-col>
          <b-col cols="12" md="4">
            <p class="fs-6">{{ translations[13521] }}</p>
            <b-select v-model="selectedPeriod" :options="periodOptions" size="sm" class="w-auto" />
          </b-col>
          <div class="col-12">
            <b-button size="sm" variant="primary" type="submit">{{ translations[13868] }}</b-button>
          </div>
        </b-row>
      </b-form>
    </template>
    <b-toast id="newsletter-toast" solid static>
      <div v-html="translations[13986]" />
    </b-toast>
  </div>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import InformationBox from '@/components/Core/InformationBox.vue';
import { CompanyModule } from '@/store/modules/company';
import { UserModule } from '@/store/modules/user';
import { subscribeCompanyNewsletter } from '@/api/Company';

@Component({
  components: {
    InformationBox,
  },
})
export default class CompanyNewsletter extends Vue {
  get isAuth() {
    return UserModule.isUserAuth;
  }

  get translations() {
    return AppModule.translation;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get companyName() {
    return CompanyModule.searchCompanyName;
  }

  get companyId() {
    return CompanyModule.companyDetailFilter.idcomp;
  }

  get applicantId() {
    return (UserModule.userInfoApplicant as any)?.applicant.id;
  }

  get applicantEmail() {
    return (UserModule.userInfoApplicant as any)?.applicant.email || '';
  }

  guestEmail = '';

  selectedPeriod = 1;

  get periodOptions() {
    return [
      { value: 1, text: `- ${this.translations[11097]}` },
      { value: 2, text: `- ${this.translations[4755]}` },
      { value: 3, text: `- ${this.translations[4756]}` },
    ];
  }

  isNewsletterAccepted = false;

  replaceCompanyPlaceholder(initialText: string) {
    return initialText?.replaceAll('$company_name', this.companyName) || '';
  }

  async submitApplicantNewsletter() {
    if (this.isNewsletterAccepted) {
      await this.subscribeNewsletter(this.applicantEmail);
    }
  }

  async subscribeGuestNewsletter() {
    if (this.guestEmail) {
      await this.subscribeNewsletter(this.guestEmail);
    }
  }

  async subscribeNewsletter(email: string) {
    await subscribeCompanyNewsletter({
      sid: this.languageSid,
      company_id: this.companyId,
      applicant_id: this.applicantId,
      email,
      periodicity: this.selectedPeriod,
    });
    this.$bvToast.show('newsletter-toast');
  }
}
</script>
