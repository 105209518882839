<template>
  <b-card no-body class="bg-white border mb-2 hover">
    <b-card-body class="p-2">
      <b-row align-h="start">
        <b-col>
          <h5
            class="fw-bolder m-0"
            :disabled="enabledTranslate || isLang"
            @mouseenter="translate(title)"
            @focusin="translate(title)"
            v-b-tooltip.hover
            :title="!enabledTranslate ? translateObject : ''"
          >
            {{ count }}.
            <a
              href="#"
              v-b-modal="isJobModalDisabled ? 'not-auth-modal' : ''"
              v-b-toggle="isJobsSidebar && !isJobModalDisabled ? 'jobs-sidebar' : ''"
              class="link-primary stretched-link"
              @click.prevent="!isJobModalDisabled && searchJob()"
            >
              <span>{{ title }}</span>
            </a>
          </h5>
          <small class="translate-title" v-if="enabledTranslate" :content="allTranslate(title)">
            {{ translateObject }}
          </small>
        </b-col>
        <b-col v-if="isNotepadAllowed" cols="auto" class="ml-auto">
          <b-button
            v-b-modal="!isAuth ? 'not-auth-modal' : ''"
            size="sm"
            variant="primary"
            class="position-relative z-3"
            @click="handleToggleNotepad"
          >
            <span v-b-tooltip.hover.bottom :title="active ? translations[14012] : translations[13596]">
              <b-icon :icon="active ? 'check' : 'star-fill'"></b-icon>
              <b-icon v-if="!isAuth" icon="shield-lock" class="ml-1"></b-icon>
            </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row no-gutters class="g-2">
        <b-col cols="auto" v-b-modal="isFirmnameDisabled ? 'not-auth-modal' : ''">
          <span class="mr-2 position-relative z-3" v-b-tooltip.hover.right :title="translations[13757]">
            <b-icon icon="building"></b-icon>
          </span>
          <span :class="{ 'user-select-none blur-5': isFirmnameDisabled }">
            {{ firmName }}
          </span>
        </b-col>
        <b-col cols="auto" class="col-auto">
          <span class="mr-2 position-relative z-3" v-b-tooltip.hover :title="translations[404]">
            <b-icon icon="geo-alt"></b-icon>
          </span>
          {{ description }}
        </b-col>
        <b-col cols="12" md="auto" class="ml-auto fs-6">
          <span class="position-relative z-3"> <b-icon icon="clock-history" class="mr-1"></b-icon>{{ date }} </span>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GoogleTranslate from '@/mixins/GoogleTranslate';
import { NotebookModule } from '@/store/modules/notebook';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';

@Component
export default class JobItemContent extends mixins(GoogleTranslate) {
  @Prop({ default: 0 }) private count!: number;

  @Prop({ default: '' }) private date!: string;

  @Prop({ default: '' }) private title!: string;

  @Prop({ default: '' }) private description!: string;

  @Prop({ default: '' }) private firmName!: string;

  @Prop({ default: '' }) private letter!: string;

  @Prop({ default: '' }) private shortview!: string;

  @Prop({ default: '' }) private sid!: string;

  @Prop({ default: 0 }) private id!: number;

  @Prop({ default: 0 }) private companyId!: number;

  @Prop({ default: 0 }) private contact!: number;

  @Prop({ default: false }) private active!: boolean;

  @Prop({ default: false }) private isAuth!: boolean;

  @Prop({ default: '' }) private update!: string;

  @Prop({ default: '' }) private translateJob!: string;

  @Prop({ default: false }) private isJobsSidebar!: boolean;

  get translations() {
    return AppModule.translation;
  }

  searchJob() {
    this.$emit('search', {
      id: this.id,
      firmName: this.firmName,
      description: this.description,
      shortview: this.shortview,
      companyId: this.companyId,
    });
  }

  get isNotepadAllowed() {
    return !this.isAuth || UserModule.accessTokenConsultant || UserModule.accessTokenApplicant;
  }

  get isFirmnameDisabled() {
    return AppModule.settingPortal.not_logged_in_blur_employer_data && !this.isAuth;
  }

  get isJobModalDisabled() {
    return AppModule.settingPortal.not_logged_in_block_job_ads && !this.isAuth;
  }

  async handleToggleNotepad() {
    if (this.isAuth) {
      if (UserModule.accessTokenConsultant || UserModule.accessTokenApplicant) {
        await NotebookModule.toogleNotepadItem({ type: 'job', item_id: this.id, isMarked: this.active });
      }
    }
  }
}
</script>
<style scoped lang="scss">
.translate-title {
  color: #212529;
  font-size: 14px;
  display: block;
}
</style>
